$(document).foundation();

$('#navigation_wrapper').on('on.zf.toggler', function(){
	console.log('menu opened');
	$('body').addClass('js-mobile-menu-open');
});

$('#btnClose').on('click', function(){
	console.log('menu closed');
	$('body').removeClass('js-mobile-menu-open');
});

$(document).ready(function(){

var indexer = 0;
var animateInterval;

function animate(){
  if(indexer == 0){
  	$(".slider .wp-block-image:nth-child(1)").css('zIndex',2);
  	$(".slider .wp-block-image:nth-child(2)").css('zIndex',3).addClass('animate__animated');
  	$(".slider .wp-block-image:nth-child(3)").css('zIndex',1).removeClass('animate__animated');
	}
  else if(indexer == 1){
		$(".slider .wp-block-image:nth-child(2)").css('zIndex',2);
		$(".slider .wp-block-image:nth-child(3)").css('zIndex',3).addClass('animate__animated');
  	$(".slider .wp-block-image:nth-child(1)").css('zIndex',1).removeClass('animate__animated');
  }
  else if(indexer == 2){
		$(".slider .wp-block-image:nth-child(3)").css('zIndex',2);
  	$(".slider .wp-block-image:nth-child(1)").css('zIndex',3).addClass('animate__animated');
  	$(".slider .wp-block-image:nth-child(2)").css('zIndex',1).removeClass('animate__animated');
	}

  if(indexer == 2) indexer = 0;
  else indexer++;
}

	if( $('.slider').length ){
		animateInterval = setInterval(animate, 4000);
		//animate();
	}

if( $('#osm').length ){

	var isDraggable = $(window).width() > 1024 ? true : false;
	var mymap = L.map('osm', {dragging: isDraggable, scrollWheelZoom:false}).setView([46.675853,5.539567], 16);

	L.tileLayer('https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png', {
		attribution: '<a href="https://www.openstreetmap.org/copyright" rel="external noopener nofollow" target="_blank">© les contributeurs d’OpenStreetMap</a>',
	}).addTo(mymap);

	var icon = L.icon({
			    iconUrl: 'http://local.juraparc.fr/wp-content/themes/juraparc/img/1x/map-icon.png',

			    iconSize:     [30, 40],
			    iconAnchor:   [15, 40],
			});
	var infoContent = '<div class="window-content"><h4>Juraparc</h4><p>Rue du 19 mars 1962<br/>39570 montmorot</p></div>';

	var bounds = new L.LatLngBounds();

	// Page Accès Juraparc - Stationnements
	if($('body').hasClass('page-id-89')){

		var icon_p1 = L.icon({
		    iconUrl: 'http://local.juraparc.fr/wp-content/themes/juraparc/img/acces/parking.png',

		    iconSize:     [77, 52],
		    iconAnchor:   [15, 40],
		});
		var marker_p1 = L.marker([46.675550,5.537934], {title: 'Parking principal', icon: icon_p1}).addTo(mymap);
		bounds.extend(marker_p1.getLatLng());

		var icon_p2 = L.icon({
		    iconUrl: 'http://local.juraparc.fr/wp-content/themes/juraparc/img/acces/parking-boeuf.png',

		    iconSize:     [121, 52],
		    iconAnchor:   [15, 40],
		});
		var marker_p2 = L.marker([46.676097,5.542980], {title: 'Parking Boeuf sur le Toit', icon: icon_p2}).addTo(mymap);
		bounds.extend(marker_p2.getLatLng());

		var icon_p3 = L.icon({
		    iconUrl: 'http://local.juraparc.fr/wp-content/themes/juraparc/img/acces/parking-dumas.png',

		    iconSize:     [71, 52],
		    iconAnchor:   [15, 40],
		});
		var marker_p3 = L.marker([46.676193,5.541767], {title: 'Parking Dumas', icon: icon_p3}).addTo(mymap);
		bounds.extend(marker_p3.getLatLng());

		var imageUrl = 'http://local.juraparc.fr/wp-content/themes/juraparc/img/acces/juraparc-batiment.png',
		imageBounds = [[46.676116,5.538880], [46.675578,5.540367]];
		L.imageOverlay(imageUrl, imageBounds).addTo(mymap);

		// Entrées
		var icon_entree_A = L.icon({
		    iconUrl: 'http://local.juraparc.fr/wp-content/themes/juraparc/img/acces/entree-A.png',

		    iconSize:     [56, 27],
		    iconAnchor:   [28, 0],
		});
		var marker_entree_A = L.marker([46.675648,5.539663], {title: 'Entrée A', icon: icon_entree_A}).addTo(mymap);
		bounds.extend(marker_entree_A.getLatLng());

		var icon_entree_B = L.icon({
		    iconUrl: 'http://local.juraparc.fr/wp-content/themes/juraparc/img/acces/entree-B.png',

		    iconSize:     [56, 27],
		    iconAnchor:   [28, 0],
		});
		var marker_entree_B = L.marker([46.675705,5.539030], {title: 'Entrée B', icon: icon_entree_B}).addTo(mymap);
		bounds.extend(marker_entree_B.getLatLng());

		var icon_entree_C = L.icon({
		    iconUrl: 'http://local.juraparc.fr/wp-content/themes/juraparc/img/acces/entree-C.png',

		    iconSize:     [69, 18],
		    iconAnchor:   [0, 9],
		});
		var marker_entree_C = L.marker([46.675758,5.540463], {title: 'Entrée C', icon: icon_entree_C}).addTo(mymap);
		bounds.extend(marker_entree_C.getLatLng());

	} else if( $('body').hasClass('page-id-125') ){
		var icon_juraparc = L.icon({
		    iconUrl: 'http://local.juraparc.fr/wp-content/themes/juraparc/img/acces/icon-juraparc.png',

		    iconSize:     [72, 63],
		    iconAnchor:   [36, 63],
		});
		var icon_mairie = L.icon({
		    iconUrl: 'http://local.juraparc.fr/wp-content/themes/juraparc/img/acces/icon-mairie.png',

		    iconSize:     [143, 84],
		    iconAnchor:   [71, 84],
		});
		var marker_juraparc = L.marker([46.675853,5.539567], {title: 'Juraparc', icon: icon_juraparc}).addTo(mymap);
		var marker_mairie = L.marker([46.671961,5.558743], {title: 'Mairie de Lons-le-Saunier', icon: icon_mairie}).addTo(mymap);
		bounds.extend(marker_juraparc.getLatLng());
		bounds.extend(marker_mairie.getLatLng());
	}
	else {
		var marker_juraparc = L.marker([46.675853,5.539567], {title: 'Juraparc', icon: icon}).bindPopup(infoContent).addTo(mymap);
		bounds.extend(marker_juraparc.getLatLng());
	}

	mymap.fitBounds(bounds, {padding: [50,50]});

	function checkResize(){
		mymap.invalidateSize();
		mymap.fitBounds(bounds, {padding: [50,50]});
	}

	window.onresize = checkResize;

}

});